import { Grid } from "@mui/material";
import React, { useState } from "react";
import { AuthService } from "../../../APIService/AuthService";
import { useSiteNameContext } from "../../../context/siteNameContext";
import { Button } from "../../../shared/ui/button";
import styles from "../../../widgets/loginForm/ui/loginForm.module.scss";
import AuthInput from "../../../components/login/AuthInput";
import { useSnackbar } from "notistack";

export function ForgotPasswordPage() {
  const { siteNameList } = useSiteNameContext();
  const [email, setEmail] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: any) => {
    const { value } = event.target;
    setEmail(value);
  };

  document.title =
    siteNameList.length &&
    siteNameList.find((x: any) => x.name === "Забыли пароль?")
      ? siteNameList.find((x: any) => x.name === "Забыли пароль?").name
      : "Забыли пароль?";

  const onClick = async () => {
    await AuthService.forgotPassword(email)
      .then(() => {
        enqueueSnackbar("Письмо успешно отправлено!", {
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      })
      .catch((e) =>
        enqueueSnackbar(e.response.data.message || e.response.data.error, {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        }),
      );
  };

  return (
    <div>
      <Grid
        direction={"column"}
        container
        className={styles.LoginContainer}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Grid item xs={12} sm={12} md={12} className={styles.Title}>
          <h3>Забыли пароль?</h3>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Grid item xs={12} sm={12} md={12}>
            <p>
              Кажется, Вы забыли свой пароль? Введите ниже свой email. Мы
              отправим Вам ссылку на восстановление пароля!
            </p>
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.Title} />
            <AuthInput
              type="email"
              placeholder="email"
              name="email"
              value={email}
              onChange={handleChange}
            />
          </Grid>
          <p />
          <p />
        </Grid>
        <Button onClick={onClick} type={"submit"}>
          Отправить
        </Button>
      </Grid>
    </div>
  );
}
