import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";

interface ITelegramFefelovaProps {
  textClassName: string;
  imageClassName: string;
}

function TelegramFefelova(props: ITelegramFefelovaProps) {
  const { textClassName, imageClassName } = props;

  return (
    <>
      <FontAwesomeIcon
        className={imageClassName}
        icon={faTelegram}
        onClick={() =>
          window.open("https://t.me/Fefelova_OM_Minimath", "_blank")
        }
      />
      <a className={textClassName} href="https://t.me/Fefelova_OM_Minimath">
        {" "}
        +7 (981) 682-86-83
      </a>
    </>
  );
}

export default TelegramFefelova;
