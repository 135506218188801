export const links = [
  {
    to: "/",
    displayName: "Главная",
  },
  {
    to: "/exam",
    displayName: "Экзамены",
  },
  {
    to: "/theme",
    displayName: "Олимпиады",
  },
];
