import React from "react";
import ReactDOM from "react-dom/client";
import "./app/styles/index.scss";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import CourseProvider from "./context/courseContext";
import SiteNameProvider from "./context/siteNameContext";
import AuthProvider from "./context/authContext";
import EditProvider from "./context/editContext";
import StudentsProvider from "./context/studentContext";
import UserInfoProvider from "./context/userContext";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <SnackbarProvider maxSnack={3}>
    <SiteNameProvider>
      <AuthProvider>
        <CourseProvider>
          <StudentsProvider>
            <UserInfoProvider>
              <EditProvider>
                <App />
              </EditProvider>
            </UserInfoProvider>
          </StudentsProvider>
        </CourseProvider>
      </AuthProvider>
    </SiteNameProvider>
  </SnackbarProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
