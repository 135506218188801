import * as React from "react";
import Logo from "../../shared/ui/logo/logo";
import { TLinkProps } from "../../types/common/LinkPropsType";
import styles from "./Footer.module.scss";

const topFooterLinkArray: TLinkProps[] = [
  {
    href: "/feedback",
    label: "Отзывы",
    colSpan: 2,
  },
  {
    href: "/parents",
    label: "Родителям",
    colSpan: 3,
  },
  {
    href: "/achievements",
    label: "Достижения",
    colSpan: 3,
  },
  {
    href: "/our-team",
    label: "Команда",
    colSpan: 2,
  },
  {
    href: "/articles",
    label: "Статьи",
    colSpan: 2,
  },
];

function FooterTop() {
  return (
    <div className={styles.LogoAndLinksContainer}>
      <Logo className={styles.Logo} />
      <div className={styles.Container}>
        {topFooterLinkArray.map((labelLink) => (
          <b>
            <a className={styles.LinkText} href={labelLink.href}>
              {labelLink.label}
            </a>
          </b>
        ))}
      </div>
    </div>
  );
}

export default FooterTop;
