import { $host } from "./index";
import { getCookie } from "react-use-cookie";

const api = process.env.REACT_APP_API_URL;
export default class TaskService {
  /**
   * Метод для получения заданий по курсу
   * @param id - айди курса
   * @param courseType - тип курса
   * @return {Promise<any>}
   */
  static async getTasksByCourseId(id: number | string, courseType: string) {
    return await $host.get(
      api + (typeof id === "number" ? courseType : "course") + "/" + id,
      { params: { user: getCookie("auth") } },
    );
  }

  /**
   * Метод для получения всех задач
   * @param courseType - тип курса
   * @return {Promise<any>}
   */
  static async getAllTasks(courseType: string) {
    return await $host.get(api + courseType);
  }

  /**
   * Метод для сохранения ответов на курс
   * @param courseType тип курса - экзамен или тема
   * @param data - данные для отправки
   * @return {Promise<any>}
   */
  static async postAnswersToCourse(courseType: string, data: any) {
    //console.log('posted:', data);
    return await $host.post(api + courseType + "/" + data.courseId, data);
  }

  /**
   * Метод для получения данных по задаче (исп. администратором)
   * @param id - айди задания
   * @return {Promise<any>}
   */
  static async getTaskById(id: number) {
    return await $host.get(api + "task/" + id);
  }

  /**
   * Метод для обновления информации по задаче
   * @param data - обновленные данные
   * @return {Promise<any>}
   */
  static async putUpdatedTask(data: any) {
    return await $host.put(api + "task" + data.id, data);
  }
}
