import FooterBottom from "../../../components/footer/FooterBottom";
import FooterMiddle from "../../../components/footer/FooterMiddle";
import FooterTop from "../../../components/footer/FooterTop";
import Logo from "../../../shared/ui/logo/logo";
import styles from "./footer.module.scss";

export function Footer() {
  return (
    <div className={styles.Footer}>
      <div className={styles.Container}>
        <Logo className={styles.Logo} />
        <hr className={styles.DisappearingLine} />
        <FooterTop />
        <hr className={styles.Line} />
        <FooterMiddle />
        <hr className={styles.Line} />
        <FooterBottom />
      </div>
    </div>
  );
}
