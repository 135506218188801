import React from "react";
import { Link, useParams } from "react-router-dom";
import { AuthService } from "../../../APIService/AuthService";
import { useAuthContext } from "../../../context/authContext";
import DropDown from "../../../shared/ui/dropDown/dropDown";
import { TUserAuth } from "../../../types/UserAuthType";

export function ProfileDropDown(props: TUserAuth) {
  const { setIsAuth } = useAuthContext();
  const { authInfo } = useAuthContext();
  const params = useParams();
  const id = params.id || authInfo.id.toString();

  const logout = () => {
    AuthService.logout();
    setIsAuth(false);
  };

  const items = [
    {
      key: "1",
      label: <Link to={"/account"}>Мой профиль</Link>,
    },
    {
      key: "2",
      label: (
        <Link to={"/"} onClick={logout}>
          Выйти
        </Link>
      ),
    },
  ];

  return <DropDown items={items} text={"ID_" + id} />;
}
