const axios = require("axios");

const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const $authHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const authInterceptor = (config: any) => {
  config.headers.Authorization = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : null;
  //console.log(config)
  return config;
};

$authHost.interceptors.request.use(authInterceptor);
$host.interceptors.request.use(authInterceptor);
export { $host, $authHost };
