import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Footer } from "../widgets/footer";
import { NavBar } from "../widgets/navBar";
import AppRoutes from "./routing/routes/AppRoutes";
import { getCookie } from "react-use-cookie";
import { useSnackbar } from "notistack";
import CookieNotification from "../components/notification/CookieNotification";

function App() {
  const { enqueueSnackbar } = useSnackbar();
  if (
    !getCookie("agreedToUseCookie") &&
    !sessionStorage.getItem("agreedToUseCookie")
  ) {
    enqueueSnackbar(<CookieNotification />, {
      variant: "default",
      autoHideDuration: null,
      hideIconVariant: false,
      preventDuplicate: true,
      style: {
        width: 350,
        height: 175,
        backgroundColor: "white",
        color: "black",
      },
      anchorOrigin: { horizontal: "left", vertical: "bottom" },
    });
  }
  return (
    <BrowserRouter>
      <NavBar />
      <div>
        <AppRoutes />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
// { !getCookie("agreedToUseCookie") &&
// !sessionStorage.getItem("agreedToUseCookie") ? <CookieNotification/>:<></>}
