import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVk,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import MinimathEmail from "../minimathSocials/MinimathEmail";
import TelegramFefelova from "../minimathSocials/TelegramFefelova";

function FooterMiddle() {
  return (
    <div className={styles.Container}>
      <div className={styles.InnerContainer}>
        <a className={styles.PlinkDisabledBig} href={"/"}>
          {" "}
          Уроки с преподавателем
        </a>

        <Link className={styles.LinkText} to={"/fefelova"}>
          Фефелова Ольга
        </Link>

        <div>
          <TelegramFefelova
            textClassName={styles.ButtonBottomLink}
            imageClassName={styles.PinkImage}
          />
        </div>
      </div>

      <div className={styles.InnerContainer}>
        <a className={styles.PlinkDisabledBig} href={"/"}>
          Задайте вопросы
        </a>

        <div>
          <MinimathEmail
            textClassName={styles.ButtonBottomLink}
            imageClassName={styles.PinkImage}
          />
        </div>

        <div className={styles.IconsContainer}>
          <FontAwesomeIcon
            className={styles.PinkImage}
            icon={faInstagram}
            onClick={() =>
              window.open("https://www.instagram.com/minimath239/", "_blank")
            }
          />
          <FontAwesomeIcon
            className={styles.PinkImage}
            icon={faYoutube}
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCvdTyDDtO3lIdul0QYltepw",
                "_blank",
              )
            }
          />

          <FontAwesomeIcon
            className={styles.PinkImage}
            onClick={() =>
              window.open("https://vk.com/club178616907", "_blank")
            }
            icon={faVk}
          />
        </div>
      </div>
    </div>
  );
}

export default FooterMiddle;
