import React, { createContext, useContext, useEffect, useState } from "react";
import CommonService from "../APIService/CommonService";

/**
 * Контекст наименования страниц в браузере
 */

export type TSiteNameContext = {
  siteNameList: any[];
};

const SiteNameContext = createContext<TSiteNameContext>({
  siteNameList: [],
});

function SiteNameProvider(props: { children: any }) {
  const [siteNameList, setSiteNameList] = useState([]);

  const siteNameListFetch = async () => {
    const response = await CommonService.getSitePageNames();
    setSiteNameList(response.data);
  };
  useEffect(() => {
    siteNameListFetch().then(() => console.log());
  }, []);
  return (
    <SiteNameContext.Provider value={{ siteNameList }}>
      {props.children}
    </SiteNameContext.Provider>
  );
}

export default SiteNameProvider;

export const useSiteNameContext = () => {
  return useContext(SiteNameContext);
};
