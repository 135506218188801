import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

interface IMinimathEmailProps {
  textClassName: string;
  imageClassName: string;
}

function MinimathEmail(props: IMinimathEmailProps) {
  const { textClassName, imageClassName } = props;
  return (
    <>
      <FontAwesomeIcon className={imageClassName} icon={faEnvelope} />
      <a
        onClick={() => window.open("mailto:minimath239@yandex.ru", "_blank")}
        href=""
        className={textClassName}
      >
        {" "}
        minimath239@yandex.ru
      </a>
    </>
  );
}

export default MinimathEmail;
