import styles from "./toggler.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

export interface ITogglerProps {
  className?: string;
  onClick: () => void;
}

export function Toggler(props: ITogglerProps) {
  const { className, onClick } = props;

  return (
    <div className={classNames(styles.select, className)} onClick={onClick}>
      <FontAwesomeIcon icon={faBars} />
    </div>
  );
}
