import { Link } from "react-router-dom";
import { ProfileDropDown } from "../../loginDropDown";
import { ReportDropDown } from "../../reportDropDown";
import styles from "./navBar.module.scss";

interface INavBarNavigationProps {
  links: { to: string; displayName: string }[];
  authInfo: any;
  onClick: () => void;
}

export function NavBarNavigation(props: INavBarNavigationProps) {
  const { links, authInfo, onClick } = props;

  return (
    <>
      <div className={styles.Navigation}>
        {links.map((link) => (
          <Link
            key={link.displayName}
            onClick={onClick}
            className={styles.Link}
            to={link.to}
          >
            {link.displayName}
          </Link>
        ))}

        {authInfo && authInfo.id ? (
          <Link onClick={onClick} className={styles.Link} to={"/lk"}>
            Обучение
          </Link>
        ) : (
          <></>
        )}

        <div className={styles.dropDown}>
          <ReportDropDown />
        </div>

        {authInfo && authInfo.access ? (
          <Link
            onClick={onClick}
            className={styles.Link}
            to={"/lk-for-teacher"}
          >
            Учительская
          </Link>
        ) : (
          <></>
        )}
      </div>

      <div className={styles.Navigation}>
        {authInfo ? (
          <div className={styles.dropDown}>
            <ProfileDropDown {...authInfo} />
          </div>
        ) : (
          <>
            <Link onClick={onClick} className={styles.Link} to={"/login"}>
              Войти
            </Link>

            <Link onClick={onClick} className={styles.Link} to={"/register"}>
              Регистрация
            </Link>
          </>
        )}
      </div>
    </>
  );
}
