import React from "react";
import { Link } from "react-router-dom";

interface ILogoProps {
  className?: string;
}

function Logo(props: ILogoProps) {
  return (
    <div className={props.className}>
      <Link to="/">
        <img width={138} height={51} src={"/images/headlogo.svg"} alt="" />
      </Link>
    </div>
  );
}

export default Logo;
