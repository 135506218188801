import React from "react";
import { Route, Routes } from "react-router-dom";
import { CourseTypeEnum } from "../../../constants/enum/courseType.enum";
import { AuthTypeEnum } from "../../../constants/enum/authType.enum";
import AdminRoute from "./AdminRoute";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import ForgotPasswordPage from "../../../pages/forgotPasswordPage";

const MainPage = React.lazy(() => import("../../../pages/mainPage"));
const MainEgePage = React.lazy(() => import("../../../pages/mainEgePage"));
const FefelovaPage = React.lazy(() => import("../../../pages/fefelovaPage"));
const LoginPage = React.lazy(() => import("../../../pages/loginPage"));

const EditPage = React.lazy(() => import("../../../pages/editPage"));
const TasksPage = React.lazy(() => import("../../../pages/tasksPage"));
const CoursesPage = React.lazy(() => import("../../../pages/coursesPage"));

const NotFoundPage = React.lazy(() => import("../../../pages/notFound"));
const ConfirmEmailPage = React.lazy(
  () => import("../../../pages/confirmEmailPage"),
);
const LkForTeacherPage = React.lazy(
  () => import("../../../pages/lkForTeacherPage"),
);
const ProfilePage = React.lazy(() => import("../../../pages/profilePage"));

const LkInfoPage = React.lazy(() => import("../../../pages/lkInfoPage"));
const AnswerLkPage = React.lazy(() => import("../../../pages/answerLkPage"));
const FMLSecretsArticlePage = React.lazy(
  () => import("../../../pages/articles/fMLSecretsArticlePage"),
);
const FeaturesOfMinimathArticlePage = React.lazy(
  () => import("../../../pages/articles/featuresOfMinimathArticlePage"),
);
const TeachingPage = React.lazy(() => import("../../../pages/teachingPage"));
const ArticlesPage = React.lazy(() => import("../../../pages/articlesPage"));
const FeedbackPage = React.lazy(() => import("../../../pages/feedbackPage"));
const OurTeamPage = React.lazy(() => import("../../../pages/ourTeamPage"));
const ParentsPage = React.lazy(() => import("../../../pages/parentsPage"));
const AchievementsPage = React.lazy(
  () => import("../../../pages/achievementsPage"),
);
const Results2022Page = React.lazy(
  () => import("../../../pages/achievements/results2022Page"),
);
const Results2021Page = React.lazy(
  () => import("../../../pages/achievements/results2021Page"),
);
const Results2020Page = React.lazy(
  () => import("../../../pages/achievements/results2020Page"),
);
const BusinessOfferPage = React.lazy(
  () => import("../../../pages/achievements/businessOfferPage"),
);
const ChangesToLessonsPage = React.lazy(
  () => import("../../../pages/achievements/changesToLessonsPage"),
);
const CongratulationsAntonPage = React.lazy(
  () => import("../../../pages/achievements/congratulationsAntonPage"),
);
const PreparationModePage = React.lazy(
  () => import("../../../pages/achievements/preparationModePage"),
);
const SummerLessonsPage = React.lazy(
  () => import("../../../pages/achievements/summerLessonsPage"),
);
const StudentsCongratulationsPage = React.lazy(
  () => import("../../../pages/achievements/studentsCongratulationsPage"),
);
const IndividualLessonsPage = React.lazy(
  () => import("../../../pages/achievements/individualLessonsPage"),
);
const AnswersToLessonsPage = React.lazy(
  () => import("../../../pages/achievements/answersToLessonsPage"),
);
const FinalOlympiadStagePage = React.lazy(
  () => import("../../../pages/articles/finalOlympiadStagePage"),
);
const SolvingProblemArrivedPage = React.lazy(
  () => import("../../../pages/articles/solvingProblemArrivedPage"),
);
const FML239BestSchoolPage = React.lazy(
  () => import("../../../pages/articles/fML239BestSchoolPage"),
);
const HowAndWhyMathPage = React.lazy(
  () => import("../../../pages/articles/howAndWhyMathPage"),
);
const Answer25Page = React.lazy(() => import("../../../pages/answer25Page"));
const CreatePage = React.lazy(() => import("../../../pages/createPage"));
const PrivacyPage = React.lazy(() => import("../../../pages/privacyPage"));
const ResetPasswordPage = React.lazy(
  () => import("../../../pages/resetPasswordPage"),
);

function AppRoutes() {
  return (
    <div>
      <Routes>
        <Route
          path={"/"}
          element={
            <PublicRoute element={<PublicRoute element={<MainPage />} />} />
          }
        />
        <Route
          path={"/ege"}
          element={
            <PublicRoute element={<PublicRoute element={<MainEgePage />} />} />
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute element={<LoginPage type={AuthTypeEnum.LOGIN} />} />
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute element={<LoginPage type={AuthTypeEnum.REGISTER} />} />
          }
        />
        <Route
          path="/fefelova"
          element={<PublicRoute element={<FefelovaPage />} />}
        />
        <Route
          path="/theme/:id"
          element={
            <PublicRoute
              element={<TasksPage courseType={CourseTypeEnum.THEME} />}
            />
          }
        />
        <Route
          path="/exam/:id"
          element={
            <PublicRoute
              element={<TasksPage courseType={CourseTypeEnum.EXAM} />}
            />
          }
        />

        <Route
          path="/admin/course/:id"
          element={
            <PublicRoute
              element={
                <AdminRoute>
                  <TasksPage isForAdmin={true} />
                </AdminRoute>
              }
            />
          }
        />
        <Route
          path="/exam"
          element={
            <PublicRoute
              element={<CoursesPage courseType={CourseTypeEnum.EXAM} />}
            />
          }
        />
        <Route
          path="/theme"
          element={
            <PublicRoute
              element={<CoursesPage courseType={CourseTypeEnum.THEME} />}
            />
          }
        />
        <Route
          path="/404"
          element={<PublicRoute element={<NotFoundPage />} />}
        />
        <Route
          path="/confirm-email"
          element={<PublicRoute element={<ConfirmEmailPage />} />}
        />
        <Route
          path="/confirm-email/:token"
          element={<PublicRoute element={<ConfirmEmailPage />} />}
        />
        <Route
          path="/articles/sekrety-fml-239-ili-pochemu-litsej-pobezhdaet-moskvichej-na-olimpiadakh"
          element={<PublicRoute element={<FMLSecretsArticlePage />} />}
        />
        <Route
          path="/articles/osobennosti-trenazhera-minimath239"
          element={<PublicRoute element={<FeaturesOfMinimathArticlePage />} />}
        />
        <Route
          path="/articles/fml-239-lider-vsosch-2022"
          element={<PublicRoute element={<FinalOlympiadStagePage />} />}
        />
        <Route
          path="/articles/the-problem-is-about-those-who-arrived-earlier-and-those-who-are-late"
          element={<PublicRoute element={<SolvingProblemArrivedPage />} />}
        />
        <Route
          path="/articles/fml-239-luchshaya-shkola-rossii-mnenie-glavnogo-trenera-sbornoj-rossii-po-matematike-kirilla-sukhova"
          element={<PublicRoute element={<FML239BestSchoolPage />} />}
        />
        <Route
          path="/articles/sergej-rukshin-kak-i-zachem-uchitsya-matematike-kak-uchit-devochek"
          element={<PublicRoute element={<HowAndWhyMathPage />} />}
        />
        <Route
          path="/teaching-lessons"
          element={<PublicRoute element={<TeachingPage />} />}
        />
        <Route
          path="/articles"
          element={<PublicRoute element={<ArticlesPage />} />}
        />
        <Route
          path="/feedback"
          element={<PublicRoute element={<FeedbackPage />} />}
        />
        <Route
          path="/our-team"
          element={<PublicRoute element={<OurTeamPage />} />}
        />
        <Route
          path="/parents"
          element={<PublicRoute element={<ParentsPage />} />}
        />
        <Route
          path="/achievements"
          element={<PublicRoute element={<AchievementsPage />} />}
        />
        <Route
          path="/achievements/2022-results"
          element={<PublicRoute element={<Results2022Page />} />}
        />
        <Route
          path="/achievements/2021-results"
          element={<PublicRoute element={<Results2021Page />} />}
        />
        <Route
          path="/achievements/2020-results"
          element={<PublicRoute element={<Results2020Page />} />}
        />
        <Route
          path="/achievements/business-offer-5-grade"
          element={<PublicRoute element={<BusinessOfferPage />} />}
        />
        <Route
          path="/achievements/changes-to-individual-lessons"
          element={<PublicRoute element={<ChangesToLessonsPage />} />}
        />
        <Route
          path="/achievements/congratulations-anton-a"
          element={<PublicRoute element={<CongratulationsAntonPage />} />}
        />
        <Route
          path="/achievements/preparation-mode"
          element={<PublicRoute element={<PreparationModePage />} />}
        />
        <Route
          path="/achievements/summer-lessons"
          element={<PublicRoute element={<SummerLessonsPage />} />}
        />
        <Route
          path="/achievements/congratulations-to-all-stundents"
          element={<PublicRoute element={<StudentsCongratulationsPage />} />}
        />
        <Route
          path="/achievements/individual-lessons"
          element={<PublicRoute element={<IndividualLessonsPage />} />}
        />
        <Route
          path="/achievements/answers-to-lessons-conditions"
          element={<PublicRoute element={<AnswersToLessonsPage />} />}
        />

        <Route
          path="/admin/task/edit/:id"
          element={
            <PublicRoute
              element={
                <AdminRoute>
                  <EditPage table={"task"} />
                </AdminRoute>
              }
            />
          }
        />

        <Route
          path="/admin/create-task"
          element={
            <PublicRoute
              element={
                <AdminRoute>
                  <CreatePage table={"task"} />
                </AdminRoute>
              }
            />
          }
        />

        <Route
          path="/admin/create-course"
          element={
            <PublicRoute
              element={
                <AdminRoute>
                  <CreatePage table={"course"} />
                </AdminRoute>
              }
            />
          }
        />

        <Route
          path="/admin/course/edit/:id"
          element={
            <PublicRoute
              element={
                <AdminRoute>
                  <EditPage table={"course"} />~
                </AdminRoute>
              }
            />
          }
        />

        <Route
          path="/lk-for-teacher"
          element={
            <PublicRoute
              element={
                <AdminRoute>
                  <LkForTeacherPage />
                </AdminRoute>
              }
            />
          }
        />

        <Route
          path="/account"
          element={
            <PublicRoute
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
          }
        />

        <Route
          path="/lk"
          element={
            <PublicRoute
              element={
                <ProtectedRoute>
                  <LkInfoPage />
                </ProtectedRoute>
              }
            />
          }
        />

        <Route
          path="/lk/:id"
          element={
            <PublicRoute
              element={
                <AdminRoute>
                  <LkInfoPage />
                </AdminRoute>
              }
            />
          }
        />

        <Route
          path="/answer-lk"
          element={<PublicRoute element={<AnswerLkPage />} />}
        />

        <Route
          path="/answer25/:id"
          element={
            <PublicRoute
              element={
                <AdminRoute>
                  <Answer25Page />
                </AdminRoute>
              }
            />
          }
        />

        <Route
          path="/privacy"
          element={<PublicRoute element={<PrivacyPage />} />}
        />

        <Route
          path="/reset/:token"
          element={<PublicRoute element={<ResetPasswordPage />} />}
        />

        <Route
          path="/forgot-password"
          element={<PublicRoute element={<ForgotPasswordPage />} />}
        />

        <Route path="*" element={<PublicRoute element={<NotFoundPage />} />} />
      </Routes>
    </div>
  );
}

export default AppRoutes;
