import React from "react";
import styles from "./button.module.scss";
import classNames from "classnames";

export function Button(props: any) {
  const ref = React.useRef();

  return (
    <button
      className={classNames(styles.Button, props.styleClass)}
      ref={ref}
      {...props}
    />
  );
}
