import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";

function FooterBottom() {
  return (
    <div className={styles.Container}>
      <a className={styles.PlinkDisabled}>
        2018-2023 | Fefelova School, All Rights Reserved
      </a>
      <a className={styles.PlinkDisabled}>ИП Фефеловой | ИНН 472001848681</a>
      <a className={styles.PlinkDisabled}>Москва Большая Якиманка 26</a>

      <Link className={styles.Plink} to={"/privacy"}>
        Политика конфиденциальности{" "}
      </Link>
    </div>
  );
}

export default FooterBottom;
