import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import AdminService from "../APIService/AdminService";
import { emptyFunction } from "../constants/emptyFunction";
import { TEditCourse } from "../types/EditCourseType";
import { TEditTask } from "../types/EditTaskType";
import { AnswerEditTypeEnum } from "../constants/enum/answerType.enum";
import { CourseTypeEnum } from "../constants/enum/courseType.enum";
import { useSnackbar } from "notistack";

/**
 * Контекст для редактирвоания курса/задачи
 */

export type TEditContext<T = TEditCourse | TEditTask> = {
  editItem: T;
  setItem: Dispatch<SetStateAction<T>>;
  handleChange: (e: any) => void;
  handleLookupTypeAnswerChange: (e: any) => void;
  handleLookupCourseTypeChange: (e: any) => void;
  handleSubmit: (e: any) => Promise<void>;
};

const EditContext = createContext<TEditContext>({
  editItem: { id: 1 } as TEditTask | TEditCourse,
  setItem: emptyFunction,
  handleChange: emptyFunction,
  handleLookupTypeAnswerChange: emptyFunction,
  handleLookupCourseTypeChange: emptyFunction,
  handleSubmit: () => new Promise(emptyFunction),
});

function EditProvider(props: { children: any }) {
  const userToken = localStorage.getItem("token");
  const { enqueueSnackbar } = useSnackbar();

  let [editItem, setItem] = useState<TEditCourse | TEditTask>({ id: 0 } as
    | TEditTask
    | TEditCourse);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (userToken) {
      try {
        if ("answer" in editItem) {
          if (!!editItem.id) {
            await AdminService.putUpdatedTask(editItem).then(() =>
              enqueueSnackbar("Данные успешно сохранены", {
                variant: "success",
                anchorOrigin: { horizontal: "right", vertical: "top" },
              }),
            );
          } else {
            await AdminService.createTask(editItem).then(() =>
              enqueueSnackbar("Данные успешно сохранены", {
                variant: "success",
                anchorOrigin: { horizontal: "right", vertical: "top" },
              }),
            );
          }
        } else {
          if (!!editItem.id) {
            await AdminService.putUpdatedCourse(editItem).then(() =>
              enqueueSnackbar("Данные успешно сохранены", {
                variant: "success",
                anchorOrigin: { horizontal: "right", vertical: "top" },
              }),
            );
          } else {
            await AdminService.createCourse(editItem).then(() =>
              enqueueSnackbar("Данные успешно сохранены", {
                variant: "success",
                anchorOrigin: { horizontal: "right", vertical: "top" },
              }),
            );
          }
        }
      } catch (e: any) {
        enqueueSnackbar(
          `Произошла ошибка при сохранении! ${e.response.data.message || e.response.data.error}`,
          {
            variant: "error",
            anchorOrigin: { horizontal: "right", vertical: "top" },
          },
        );
      }
    }
  };

  const handleChange = (e: any) => {
    setItem((editItem) => {
      let item = Object.assign({}, editItem); // creating copy of state variable jasper
      // @ts-ignore
      item[e.target.id] = e.level
        ? e.level.content
        : e.target.type === "number"
          ? parseInt(e.target.value)
          : e.target.value;
      // update the name property, assign a new value
      return item;
    });
  };
  const handleLookupTypeAnswerChange = (e: any) => {
    setItem((editItem) => {
      let item = Object.assign({}, editItem);
      // @ts-ignore
      item["answerType"] =
        (AnswerEditTypeEnum[e as keyof typeof AnswerEditTypeEnum] as string) ||
        null; // update the name property, assign a new value
      return item;
    });
  };

  const handleLookupCourseTypeChange = (e: any) => {
    setItem((editItem) => {
      let item = Object.assign({}, editItem);
      // @ts-ignore
      item["urlCode"] = CourseTypeEnum[
        e as keyof typeof CourseTypeEnum
      ] as string; // update the name property, assign a new value
      return item;
    });
  };

  return (
    <EditContext.Provider
      value={{
        editItem,
        setItem,
        handleChange,
        handleSubmit,
        handleLookupTypeAnswerChange,
        handleLookupCourseTypeChange,
      }}
    >
      {props.children}
    </EditContext.Provider>
  );
}

export default EditProvider;

export const useEditContext = () => {
  return useContext(EditContext);
};
