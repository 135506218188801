import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { emptyFunction } from "../constants/emptyFunction";
import { TUserAuth } from "../types/UserAuthType";
import useCookie, { getCookie } from "react-use-cookie";
import { v4 } from "uuid";

/**
 * Контекст авторизации
 */

export type TAuthContext = {
  isAuth: boolean;
  authInfo: TUserAuth;
  setIsAuth: Dispatch<SetStateAction<boolean>>;
};

export const AuthContext = createContext<TAuthContext>({
  isAuth: false,
  authInfo: {
    id: 0,
    email: "",
    access: "",
    iat: 0,
    exp: 0,
  },
  setIsAuth: emptyFunction,
});

function AuthProvider(props: { children: any }) {
  const [isAuth, setIsAuth] = useState(false);
  const [userInfo, setUserInfo] = useState<TUserAuth>({
    id: 0,
    email: "",
    access: "",
    iat: 0,
    exp: 0,
  });

  const [userToken, setUserToken] = useCookie("auth", v4());

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      setIsAuth(true);
      setUserInfo(JSON.parse(localStorage.getItem("auth") || "") || undefined);
      setUserToken(userInfo.id.toString(), {
        days: 365,
        SameSite: "Strict",
        Secure: true,
      });
    } else {
      if (!getCookie("auth"))
        setUserToken(v4(), {
          days: 365,
          SameSite: "Strict",
          Secure: true,
        });
    }
  }, [isAuth]);
  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth, authInfo: userInfo }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
export const useAuthContext = () => {
  return useContext(AuthContext);
};
