import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/authContext";
import DropDown from "../../../shared/ui/dropDown/dropDown";

export function ReportDropDown() {
  const { authInfo } = useAuthContext();

  const items = authInfo.id
    ? [
      {
        key: "1",
        label: (
          <Link to={"/answer-lk"}>
            Результаты
          </Link>
        ),
      },
      {
        key: "2",
        label: (
          <Link
            to={`/answer25/${authInfo.id}`}
          >
            Отчет 25
          </Link>
        ),
      },
    ]
    : [
      {
        key: "1",
        label: <Link to={"/answer-lk"}>Результаты</Link>,
      },
    ];

  return <DropDown items={items} text={"Отчеты"} />;
}

