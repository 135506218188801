import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { TStudentInfoType } from "../types/StudentInfoType";
import { emptyFunction } from "../constants/emptyFunction";

/**
 * Контекст учеников
 */

export type TStudentsContext = {
  studentInfoList: TStudentInfoType[];
  setUserInfoList: Dispatch<SetStateAction<TStudentInfoType[]>>;
};

const StudentsContext = createContext<TStudentsContext>({
  studentInfoList: [],
  setUserInfoList: emptyFunction,
});

function StudentsProvider(props: { children: any }) {
  const [studentInfoList, setUserInfoList] = useState<TStudentInfoType[]>([]);
  return (
    <StudentsContext.Provider value={{ studentInfoList, setUserInfoList }}>
      {props.children}
    </StudentsContext.Provider>
  );
}

export default StudentsProvider;
export const useStudentsContext = () => {
  return useContext(StudentsContext);
};
