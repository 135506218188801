import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import TaskService from "../APIService/TaskService";
import { emptyFunction, emptyPromise } from "../constants/emptyFunction";
import { TCourse, TCourseThemes } from "../types/CourseType";
import { TTask } from "../types/TaskType";
import { getForbiddenSymbols } from "../helpers/forbiddenSymbolsHelper";
import { TChecked } from "../types/CheckedAnswerType";
import { CourseTypeEnum } from "../constants/enum/courseType.enum";
import { useParams } from "react-router-dom";
import { getCookie } from "react-use-cookie";

/**
 * Контекст курсов/экзаменов
 */

export type TCourseContext = {
  courseType?: CourseTypeEnum;
  onCourseTypeUpdated: Dispatch<CourseTypeEnum>;
  taskList: any[];
  setTasksContext: Dispatch<SetStateAction<TTask[]>>;
  setCourse: Dispatch<SetStateAction<TCourse>>;
  course: any;
  coursesList: TCourseThemes[];
  courseIds: number[];
  isClickedCheck: boolean;
  setIsClickedCheck: Dispatch<SetStateAction<boolean>>;
  setCourseIds: Dispatch<SetStateAction<number[]>>;
  setCoursesContext: Dispatch<SetStateAction<TCourseThemes[]>>;
  checkedTasks: any;
  setCheckedTasks: Dispatch<SetStateAction<TChecked[]>>;
  handleSubmit: (e: any) => Promise<void>;
  handleChange: (e: any) => void;
  isCheckButtonDisabled: boolean;
  setCheckButtonDisabled: (e: any) => void;
  hasForbiddenSymbols: any[];
  resetAll: () => void;
  answersList: any;
  iteration: number;
  setIteration: Dispatch<SetStateAction<number>>;
  courseTypeForTasks?: string;
  setCourseTypeForTasks: Dispatch<SetStateAction<string>>;
};

const CourseContext = createContext<TCourseContext>({
  courseType: undefined,
  onCourseTypeUpdated: emptyFunction,
  taskList: [],
  setTasksContext: emptyFunction,
  setCourse: emptyFunction,
  isClickedCheck: false,
  setIsClickedCheck: emptyFunction,
  course: {},
  coursesList: [],
  courseIds: [],
  setCourseIds: emptyFunction,
  setCoursesContext: emptyFunction,
  checkedTasks: [],
  setCheckedTasks: emptyFunction,
  handleSubmit: emptyPromise,
  handleChange: emptyFunction,
  isCheckButtonDisabled: false,
  setCheckButtonDisabled: emptyFunction,
  hasForbiddenSymbols: [],
  resetAll: emptyFunction,
  answersList: {},
  iteration: 1,
  setIteration: emptyFunction,
  courseTypeForTasks: undefined,
  setCourseTypeForTasks: emptyFunction,
});

function CourseProvider(props: { children: any }) {
  const userInfo: any = getCookie("auth");
  const params = useParams().courseType;
  const [courseType, setCourseType] = useState<CourseTypeEnum>(
    params as CourseTypeEnum,
  );
  const onCourseTypeUpdated = (e: CourseTypeEnum) => {
    setCourseType(e);
    setCoursesContext([]);
  };

  const [courseTypeForTasks, setCourseTypeForTasks] = useState<string>(
    params || "",
  );

  const [taskList, setTasksContext] = useState<TTask[]>([]);
  const [coursesList, setCoursesContext] = useState<TCourseThemes[]>([]);
  const [courseIds, setCourseIds] = useState<number[]>([]);
  const [answersList, setAnswersContext] = useState<any>({});
  const [iteration, setIteration] = useState<number>(1);
  const [course, setCourse] = useState<TCourse>({
    id: 0,
    urlCode: "",
    header1: "",
    title: "",
    name: "",
  });
  const [checkedTasks, setCheckedTasks] = useState<TChecked[]>([]);
  const [isCheckButtonDisabled, setCheckButtonDisabled] =
    useState<boolean>(true);
  const [hasForbiddenSymbols, setHasForbiddenSymbols] = useState<
    { taskId?: string; correct?: boolean }[]
  >([]);
  const [isClickedCheck, setIsClickedCheck] = useState<boolean>(false);

  const checkAndButtonStatus = (
    tmpName: string,
    taskType: string,
    value: any,
  ) => {
    //  const taskNumber = Number(tmpName[1]);
    let forbiddenSymbols = getForbiddenSymbols(taskType);
    const existingCheckResult = hasForbiddenSymbols.find(
      (x) => x.taskId === tmpName,
    );

    if (value && value.match(forbiddenSymbols) && !existingCheckResult) {
      setHasForbiddenSymbols([
        ...hasForbiddenSymbols,
        { taskId: tmpName, correct: false },
      ]);
    } else if (value && !value.match(forbiddenSymbols) && existingCheckResult) {
      setHasForbiddenSymbols([
        ...hasForbiddenSymbols.filter((x) => x.taskId !== tmpName),
      ]);
    } else if (!value.length) {
      setHasForbiddenSymbols([
        ...hasForbiddenSymbols.filter((x) => x.taskId !== tmpName),
      ]);
    }
  };

  const setAnswersAfterCheck = (tmpName: string[], value: any) => {
    if (!value.length) {
      setAnswersContext(delete { ...answersList }[Number(tmpName[1])]);
    } else {
      if (tmpName.length === 2) {
        setAnswersContext({ ...answersList, [tmpName[1]]: value });
      } else if (tmpName.length === 3 && value.length) {
        setAnswersContext({
          ...answersList,
          [tmpName[1] + "_" + tmpName[2]]: value,
        });
      }
    }
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setCheckedTasks([]);
    setIsClickedCheck(true);
    const a = await TaskService.postAnswersToCourse(
      course.urlCode || courseTypeForTasks,
      {
        courseId: course.id,
        answers: answersList,
        user: userInfo ? userInfo : "",
      },
    );
    setCheckedTasks(a.data);
  };

  const handleChange = (e: any) => {
    const tmpName =
      e.target.id.length === 0
        ? e.target.parentElement.parentElement.parentElement.id.split("_")
        : e.target.id.split("_");
    const { value } = e.target;

    checkAndButtonStatus(
      e.target.id.length === 0
        ? e.target.parentElement.parentElement.parentElement.id
        : e.target.id,
      tmpName[0],
      value,
    );
    setAnswersAfterCheck(tmpName, value);
  };
  const resetAll = () => {
    setIsClickedCheck(false);
    setAnswersContext({});
    // setCheckedTasks([]);
    // setAnswersContext([]);
  };

  return (
    <CourseContext.Provider
      value={{
        courseType,
        onCourseTypeUpdated,
        taskList,
        answersList,
        setTasksContext,
        setCourse,
        course,
        coursesList,
        isClickedCheck,
        setIsClickedCheck,
        courseIds,
        setCourseIds,
        setCoursesContext,
        checkedTasks,
        setCheckedTasks,
        handleSubmit,
        handleChange,
        isCheckButtonDisabled,
        setCheckButtonDisabled,
        hasForbiddenSymbols,
        resetAll,
        iteration,
        setIteration,
        courseTypeForTasks,
        setCourseTypeForTasks,
      }}
    >
      {props.children}
    </CourseContext.Provider>
  );
}

export default CourseProvider;

export const useCourseContext = () => {
  return useContext(CourseContext);
};
