import { $authHost } from "./index";
import { TUserInfo } from "../types/UserInfoType";

const api = "/user/";

export class UserService {
  static async deleteUser(id: number) {
    return await $authHost.delete(api + id);
  }

  static async getUserInfo(id: number) {
    return await $authHost.get(api + id);
  }

  static async updateUserInfo(body: TUserInfo) {
    return await $authHost.put(api + body.id, body);
  }

  static async updatePassword(id: number, body: { newPassword: string }) {
    return await $authHost.put(api + "update-password/" + id, body);
  }
}
